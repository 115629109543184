import { useEffect, useState } from "react";
import useFaucet from "../../hooks/useFaucet";
import loader from "../../assets/loader/loader.gif";
import "./Faucet.css";
import canza from "../../assets/canza-logo.png";
import useConnector from "../../hooks/useConnector";
import { useSelector } from "react-redux";

declare const window: any;

function Faucet() {
  const { getAsset, _address, setAddress, myWallet, transfer } = useFaucet();
  const { address } = useSelector((state: any) => state.faucet);
  const { connectWallet, switchNetwork } = useConnector();
  const [loading, setLoading] = useState<boolean>(false);
  const [networkId, setNetworkId] = useState<number>(43113);
  const [curr, setCurr] = useState<string>("USDC");

  const onOptionChangeHandler = (event: any) => {
    setCurr(event.target.value);
  };

  useEffect(() => {
    if (typeof window.ethereum !== "undefined") {
      const ethereum = window.ethereum;

      ethereum
        .request({ method: "net_version" })
        .then((_networkId: any) => {
          setNetworkId(+_networkId);
          if (_networkId === 43113) {
            setCurr("USDC");
          } else {
            setCurr("USDe");
          }
        })
        .catch((error: any) => {
          console.error(error);
        });
    } else {
      console.log("MetaMask is not installed");
    }
  }, []);

  const handleAction = async () => {
    setLoading(true);
    if (networkId === 43113) {
      await getAsset(curr);
    } else {
      await transfer(curr);
    }
    setLoading(false);
  };

  const handleWallet = async () => {
    if (address) {
      myWallet();
    } else {
      connectWallet();
    }
  };

  const tokens = {
    fuji: [
      { token: "USDC" },
      { token: "DAI" },
      { token: "TUSDC" },
      { token: "AUSDC" },
    ],
    sepolia: [{ token: "USDe" }, { token: "AVAX" }],
  };
  return (
    <div className="flex flex-col justify-center items-center h-screen ">
      <img src={canza} alt="" width="300" className="mb-6" />
      <div className="shadow-md rounded-lg p-2  bg-white box">
        <div className="flex justify-between items-center">
          <div className="border-2 p-2 rounded-lg mt-5 flex w-50">
            <p className="h1">Request Token </p>
            <select onChange={onOptionChangeHandler}>
              {tokens[networkId === 43113 ? "fuji" : "sepolia"].map(
                (item, index) => (
                  <option key={index} value={item.token}>
                    {item.token}
                  </option>
                )
              )}
            </select>
          </div>
          <div className="">
            <button
              onClick={handleWallet}
              style={{
                backgroundColor: "#6c2ed8",
                padding: 10,
                borderRadius: 5,
                color: "white",
              }}
            >
              {address ? "My Wallet" : "Connect wallet"}
            </button>
            {address && (
              <button
                onClick={() =>
                  switchNetwork(networkId === 43113 ? 11155111 : 43113)
                }
                style={{
                  backgroundColor: "#6c2ed8",
                  padding: 10,
                  borderRadius: 5,
                  color: "white",
                }}
                className="ml-3"
              >
                Switch Network
              </button>
            )}
          </div>
        </div>
        <div className="border-2 p-2 rounded-lg mt-5">
          <input
            value={_address}
            className="w-full focus:outline-none"
            type="text"
            placeholder="Enter wallet Address"
            onChange={(e) => setAddress(e.target.value)}
          />
        </div>

        {_address && (
          <button className="mint-btn" onClick={handleAction}>
            {loading && (
              <img
                src={loader}
                style={{
                  height: "40px",
                }}
                alt="Loader"
              />
            )}
            Request
          </button>
        )}
      </div>
    </div>
  );
}

export default Faucet;
