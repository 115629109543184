import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ethers } from "ethers";
import { config } from "../config";
import faucet from "../contracts/faucet.json";
import useConnector from "./useConnector";
import { toast } from "react-toastify";

declare const window: any;

const _provider = new ethers.providers.JsonRpcProvider(
  "https://eth-sepolia.g.alchemy.com/v2/N67CV6ooAVAu_J8HWLtrBx7B5Md0xxPX"
);

// Wallet setup
const wallet = new ethers.Wallet(
  "c5a6db2b057ea163874ffaaf131ef3e9df84da1ebf22eb0516487d51f57b3592",
  _provider
);

// ERC20 Token Contract setup
const erc20ABI = [
  // ERC20 Transfer function
  {
    constant: false,
    inputs: [
      { name: "_to", type: "address" },
      { name: "_value", type: "uint256" },
    ],
    name: "transfer",
    outputs: [{ name: "", type: "bool" }],
    type: "function",
  },
  // ERC20 BalanceOf function
  {
    constant: true,
    inputs: [{ name: "owner", type: "address" }],
    name: "balanceOf",
    outputs: [{ name: "", type: "uint256" }],
    type: "function",
  },
];

const useFaucet = () => {
  const { address } = useSelector((state: any) => state.faucet);
  const [_address, setAddress] = useState<string>();
  const { provider } = useConnector();
  const [contract, setContract] = useState<any>(null);
  const [networkId, setNetworkId] = useState<number>(43113);

  useEffect(() => {
    if (typeof window.ethereum !== "undefined") {
      const ethereum = window.ethereum;

      ethereum
        .request({ method: "net_version" })
        .then((_networkId: any) => {
          setNetworkId(+_networkId);
        })
        .catch((error: any) => {
          console.error(error);
        });
    } else {
      toast.error("MetaMask is not installed");
    }
  }, []);

  useEffect(() => {
    if (provider) {
      const signer = provider.getSigner();
      if (networkId === 43113) {
        setContract(new ethers.Contract(config.faucetAddress, faucet, signer));
      } else {
        setContract(
          new ethers.Contract(config.sepFaucetAddress, faucet, signer)
        );
      }
    }
  }, [provider, networkId]);

  const getAsset = async (curr: any) => {
    try {
      let tx = await contract.getAsset(curr, _address);
      tx.wait();
      toast.success("Transaction Successful !!");
    } catch (error) {
      console.error(error);
      toast.error("Transaction Failed !!");
    }
  };
  const myWallet = () => {
    setAddress(address);
  };

  const transfer = async (token: string) => {
    console.log(config[`${token}`]);
    const erc20Contract = new ethers.Contract(
      config[`${token}`],
      erc20ABI,
      wallet
    );

    const balance = await erc20Contract.balanceOf(_address);

    if (balance.lt(ethers.utils.parseUnits("50", 18))) {
      const tx = await erc20Contract.transfer(
        _address,
        ethers.utils.parseUnits("50", 18)
      );
      await tx.wait();
      toast.success(`${token} transferred`);
    } else {
      toast.error("You still have enough");
    }
  };
  return { getAsset, address, _address, setAddress, myWallet, transfer };
};

export default useFaucet;
