import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

// axios.defaults.headers.common["apikey"] = config.exchangeRatesAPIKEY;
export interface State {
  address: string;
}

const initialState: State = {
  address: "",
};

export const faucetSlice = createSlice({
  name: "Faucet",
  initialState,
  reducers: {
    updateAddress: (state, action: PayloadAction<string>) => {
      state.address = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateAddress } = faucetSlice.actions;

export default faucetSlice.reducer;
