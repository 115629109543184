/* eslint-disable @typescript-eslint/no-unused-vars */
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Error from "./pages/Error/Error";
import { ToastContainer } from "react-toastify";
import Page404 from "./pages/Page404/Page404";
import Faucet from "./pages/Faucet/Faucet";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Faucet />} />
        <Route path="/error" element={<Error />} />
        <Route path="*" element={<Page404 />} />
      </Routes>
      <ToastContainer />
    </Router>
  );
}

export default App;
