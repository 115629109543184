/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { ethers } from "ethers";
import { useDispatch } from "react-redux";
import { updateAddress } from "../redux/reducers/faucetReducer";

declare const window: any;

function useConnector() {
  const dispatch = useDispatch();

  const [provider, setProvider] = useState<any>(null);

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on("chainChanged", () => {
        window.location.replace("/");
      });
      window.ethereum.on("accountsChanged", () => {
        window.location.reload();
      });
    }
  });
  useEffect(() => {
    // Set Provider
    setProvider(new ethers.providers.Web3Provider(window.ethereum));
  }, []);

  const connectWallet = async () => {
    provider
      .send("eth_requestAccounts")
      .then(async (result: Array<string>) => {
        dispatch(updateAddress(result[0]));
      })
      .catch((error: any) => {
        console.error(error);
      });
  };

  const switchNetwork = async (chainId: number) => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: `0x${Number(chainId).toString(16)}` }],
      });
    } catch {
      alert("Error, please add network to metamask");
    }
  };

  return {
    connectWallet,
    switchNetwork,
    provider,
  };
}

export default useConnector;
