export const config: any = {
  faucetAddress: "0x16A4C191DB629ABC4c2c416b09eB4675A639D3C3",
  sepFaucetAddress: "0xAecD5DDC76f37d23D62aDD3F4cc8207a9D398798",
  USDC: "0xA3057965dFd404096e1e307a3Cf0f40e0250730D",
  TUSDC:"0xa6F01e68444548C5FDbDd4b64f96BeC3D3C2eFb2",
  AUSDC:"0xd2d06e5ADEA32470Ea2b2D9e07377b6bAAB37f60",
  DAI: "0x791e2a9F7671A90A04465691eAE56CC9CF2FD92E",
  WETH: "0x02BccD24905283Fd0ebaC64C88Eb3238F58C9797",
  USDT: "0x22169302224C26e424a0377169E34b1FC72e8635",
  TUSD: "0xCcCA6d391052451AB4efc955c7f12Dc1dC7D7483",
  BUSD: "0x4BF9d5F865679220592BE53853B8fBAEb30873e1",
  USDe: "0x6779dFe70FE04dBC5a62f0C360AC2308B1A146Df",
  AVAX: "0x8E8dA40BEa007C707f3F3e22948CC127e6dA08Bc",

  networks: [
    {
      chainId: `0x${Number(43113).toString(16)}`,
      rpcUrls: ["https://api.avax-test.network/ext/bc/C/rpc"],
      name: "Avalanche",
      nativeCurrency: {
        name: "AVAX",
        symbol: "AVAX",
        decimals: 18,
      },
      blockExplorerUrls: ["https://explorer.avax.network/"],
    },
  ] };
